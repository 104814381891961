<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Métodos de pago</h1>

    <div class="control add-global-controls">
      <b-button
        type="is-primary"
        class="special-margin"
        @click="openChangeCalculator()"
      >
        Abrir calculadora de cambio</b-button
      >
    </div>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>. Si desea borrar un
      método de pago solo deje el campo de forma de pago en "Sin forma de pago".
      Los campos sin forma de pago no seran tomas en cuenta.
    </div>

    <h3 class="form-division">Información general</h3>

    <b-checkbox
      v-model="sale.sendMaterial"
      class="permissions-checkbox first-row-checkbox special-margin"
    >
      Marcar material como enviado
    </b-checkbox>

    <b-checkbox
      v-if="
        checkIfCanInvoice(clientInformation._id) &&
        userPermissions &&
        userPermissions.accounting.saveInvoice === 'S'
      "
      :disabled="
        sale.requireInvoiceAfterSend || sale.requireParcialInvoiceAfterSend
      "
      v-model="sale.requireInvoice"
      class="permissions-checkbox first-row-checkbox special-margin"
      @input="handleRequireParcialInvoiceAfterSend()"
    >
      Facturar venta total
    </b-checkbox>

    <b-checkbox
      v-if="
        checkIfCanInvoice(clientInformation._id) &&
        userPermissions &&
        userPermissions.accounting.saveInvoice === 'S'
      "
      :disabled="sale.requireInvoice || sale.requireParcialInvoiceAfterSend"
      v-model="sale.requireInvoiceAfterSend"
      class="permissions-checkbox first-row-checkbox special-margin"
      @input="handleRequireParcialInvoiceAfterSend()"
    >
      Facturar venta total despues de primer envio de material
    </b-checkbox>

    <b-checkbox
      v-if="
        checkIfCanInvoice(clientInformation._id) &&
        userPermissions &&
        userPermissions.accounting.saveInvoice === 'S'
      "
      :disabled="sale.requireInvoice || sale.requireInvoiceAfterSend"
      v-model="sale.requireParcialInvoiceAfterSend"
      class="permissions-checkbox first-row-checkbox special-margin"
      @input="handleRequireParcialInvoiceAfterSend()"
    >
      Facturar venta parcial despues cada envio de material
    </b-checkbox>

    <b-checkbox v-if="checkCanDivideCashInvoice()" v-model="sale.divideCFDI">
      Dividir facturas no mayores a $2000.00
    </b-checkbox>

    <b-table
      pagination-position="both"
      :data="newPayment"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      ref="payments"
      custom-row-key="currentIndex"
      paginated
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      class="purchase-order-table"
      scrollable
      detail-key="currentIndex"
      detailed
      :opened-detailed="openedDetails"
    >
      <template #empty>
        <div class="has-text-centered">No hay métodos de pago</div>
      </template>

      <template slot-scope="props">
        <b-table-column label="Monto">
          <MoneyInput
            currency="MXN"
            :digits="5"
            placeholder="Ejemplo: 16"
            :required="false"
            v-model="props.row.MONTO"
            :disabled="true"
          >
          </MoneyInput>
        </b-table-column>

        <b-table-column label="Recibido">
          <MoneyInput
            currency="MXN"
            :digits="5"
            placeholder="Ejemplo: 16"
            :required="true"
            v-model="props.row.PAGO_PARC"
            :disabled="false"
            @input="
              () => {
                calculateChange();
                checkCredit(props.row);
              }
            "
          >
          </MoneyInput>
        </b-table-column>

        <b-table-column label="Cambio">
          <MoneyInput
            currency="MXN"
            :digits="5"
            placeholder="Ejemplo: 16"
            :required="false"
            v-model="props.row.CAMBIO"
            :disabled="true"
          >
          </MoneyInput>
        </b-table-column>

        <b-table-column label="Método de pago">
          <b-field grouped>
            <b-field expanded label-position="inside">
              <b-select
                placeholder="Ejemplo: Transferencia"
                expanded
                icon="credit-card-plus-outline"
                class="required-input"
                required
                v-model="props.row.FORMA_P"
                @input="
                  () => {
                    addPaymenMethod();
                    checkCredit(props.row);
                  }
                "
              >
                <option value="">Sin forma de pago</option>
                <option
                  value="CR"
                  v-if="
                    clientInformation.LIMITE_CRED &&
                    clientInformation.LIMITE_CRED -
                      clientInformation.BALANCE_CRED >
                      0
                  "
                >
                  Crédito
                </option>
                <option
                  :value="singlePaymentType._id"
                  v-for="singlePaymentType in paymentTypes"
                  v-bind:key="singlePaymentType._id"
                >
                  {{ singlePaymentType.DESCRIBE_P }}
                </option>
              </b-select>
            </b-field>
          </b-field>
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <div v-if="!props.row.FORMA_P">
                Seleccione un método de pago primero para ver sus detalles
              </div>

              <div v-if="props.row.FORMA_P && props.row.FORMA_P !== 'CR'">
                <div v-if="props.row.FORMA_P === efectivoPaymentId">
                  <h3 class="form-division">Cambio recibido</h3>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Cantidad de billetes de 1000 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="cambio.MIL"
                        @input="updateQuantityPaid(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Cantidad de billetes de 500 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="cambio.QUINIENTOS"
                        @input="updateQuantityPaid(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Cantidad de billetes de 200 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="cambio.DOSCIENTOS"
                        @input="updateQuantityPaid(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Cantidad de billetes de 100 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="cambio.CIEN"
                        @input="updateQuantityPaid(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Cantidad de billetes de 50 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="cambio.CINCUENTA"
                        @input="updateQuantityPaid(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Cantidad de billetes de 20 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="cambio.VEINTE"
                        @input="updateQuantityPaid(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Cantidad de monedas de 10 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="cambio.DIEZ"
                        @input="updateQuantityPaid(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Cantidad de monedas de 5 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="cambio.CINCO"
                        @input="updateQuantityPaid(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Cantidad de monedas de 2 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="cambio.DOS"
                        @input="updateQuantityPaid(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Cantidad de monedas de 1 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="cambio.UNO"
                        @input="updateQuantityPaid(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Cantidad de monedas de 0.50 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="cambio.CINCUENTA_CEN"
                        @input="updateQuantityPaid(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>
                  </b-field>

                  <h3 class="form-division">Cambio devuelto</h3>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Devuelto total (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="props.row.DEVUELTO"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Cantidad de billetes de 1000 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="devuelto.MIL"
                        @input="updateChangeTotal(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Cantidad de billetes de 500 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="devuelto.QUINIENTOS"
                        @input="updateChangeTotal(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Cantidad de billetes de 200 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="devuelto.DOSCIENTOS"
                        @input="updateChangeTotal(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Cantidad de billetes de 100 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="devuelto.CIEN"
                        @input="updateChangeTotal(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Cantidad de billetes de 50 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="devuelto.CINCUENTA"
                        @input="updateChangeTotal(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Cantidad de billetes de 20 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="devuelto.VEINTE"
                        @input="updateChangeTotal(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Cantidad de monedas de 10 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="devuelto.DIEZ"
                        @input="updateChangeTotal(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Cantidad de monedas de 5 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="devuelto.CINCO"
                        @input="updateChangeTotal(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Cantidad de monedas de 2 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="devuelto.DOS"
                        @input="updateChangeTotal(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Cantidad de monedas de 1 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="devuelto.UNO"
                        @input="updateChangeTotal(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Cantidad de monedas de 0.50 (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 5"
                        expanded
                        icon="cash"
                        v-model="devuelto.CINCUENTA_CEN"
                        @input="updateChangeTotal(props.row)"
                        type="number"
                        step="any"
                      ></b-input>
                    </b-field>
                  </b-field>
                </div>

                <b-field grouped class="special-margin">
                  <b-field
                    label="Observaciones (Opcional)"
                    expanded
                    label-position="inside"
                  >
                    <b-input
                      placeholder="Ejemplo: Pago realizado el día Martes"
                      expanded
                      v-model="props.row.OBSERVACIONES"
                      type="textarea"
                    ></b-input>
                  </b-field>
                </b-field>
              </div>

              <div v-if="props.row.FORMA_P && props.row.FORMA_P === 'CR'">
                <b-field grouped class="special-margin">
                  <b-field
                    label="Días de crédito (Requerido)"
                    expanded
                    label-position="inside"
                    required
                  >
                    <b-input
                      placeholder="Ejemplo: 15"
                      expanded
                      v-model="sale.PERI_PAGO"
                      type="number"
                      step="any"
                    ></b-input>
                  </b-field>
                </b-field>
              </div>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <div class="add-global-controls">
      <b-button type="is-success" @click="savePayment()"
        >Agregar venta</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../event-bus.js";
import {
  paymentTypeEfectivo,
  paymentTypePorDefinir,
  paymentTypeDebitCard,
  paymentTypeCreditCard,
} from "../../../utils/env-variables-fns";
import CalculateChangeModal from "./CalculateChangeModal";
import { toFixedNoRound } from "@/utils/fns";
// @ is an alias to /src
export default {
  name: "AddPaymentMethodsPos",
  props: ["ticketId", "saleInformation", "clientInformation", "posId"],
  components: {},
  data() {
    return {
      sale: this.saleInformation,
      efectivoPaymentId: paymentTypeEfectivo,
      debitCardId: paymentTypeDebitCard,
      creditCardId: paymentTypeCreditCard,
      porDefinirPaymentId: paymentTypePorDefinir,
      newPayment: [
        {
          FORMA_P: "",
          PAGO_PARC: 0,
          DEVUELTO: 0,
          MONTO: this.saleInformation.total,
          CAMBIO: 0,
          STATUS: "AC",
          FECHA_PAGO: new Date(),
          OBSERVACIONES: "",
          createMovement: false,
          affectBalance: false,
          requireCDFI: false,
          currentIndex: 0,
        },
      ],
      cambio: {
        MIL: 0,
        QUINIENTOS: 0,
        DOSCIENTOS: 0,
        CIEN: 0,
        CINCUENTA: 0,
        VEINTE: 0,
        DIEZ: 0,
        CINCO: 0,
        DOS: 0,
        UNO: 0,
        CINCUENTA_CEN: 0,
        TOTAL: 0,
      },
      devuelto: {
        MIL: 0,
        QUINIENTOS: 0,
        DOSCIENTOS: 0,
        CIEN: 0,
        CINCUENTA: 0,
        VEINTE: 0,
        DIEZ: 0,
        CINCO: 0,
        DOS: 0,
        UNO: 0,
        CINCUENTA_CEN: 0,
      },
      openedDetails: [0],
      currentIndex: 0,
      newPaymentError: {},
      currentPage: 1,
      perPage: 10,
    };
  },
  async mounted() {
    if (this.clientInformation.CLAVE_AGRUPACION_V2) {
      let creditResponse = await this.$store.dispatch(
        "GETCLIENTV2CREDITLIMIT",
        {
          groupId: this.clientInformation.CLAVE_AGRUPACION_V2,
        }
      );

      if (creditResponse.creditLitmit) {
        this.clientInformation.LIMITE_CRED = creditResponse.creditLitmit;
        this.clientInformation.BALANCE_CRED = creditResponse.creditUsed;
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves or
     */
    async savePayment() {
      try {
        const validPayments = this.validatesPayments();
        if (validPayments.missingQuantity > 0) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Falta $${validPayments.missingQuantity} por liquidar de la venta`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }

        if (!this.validChange()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `La cantidad total de cambio devuelto es mayor a la cantidad de cambio calculada`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }

        let response = await this.$store.dispatch("SAVEPOSSALE", {
          ticketId: this.ticketId,
          sale: this.transformSale(this.sale, validPayments.validPayments),
          payments: validPayments.validPayments,
          cambio: this.cambio,
          devuelto: this.devuelto,
          posId: this.posId,
        });

        if (response.success) {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardó exitosamente la venta",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              EventBus.$emit("posSaleSaved", {
                saleInformation: this.sale,
                responseInformation: response,
              });
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al guardar la venta en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al guardar la venta`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc calculates the change needed
     */
    calculateChange() {
      for (const [
        singlePaymentIndex,
        singlePayment,
      ] of this.newPayment.entries()) {
        // Check how much is missing to pay
        if (singlePayment.PAGO_PARC - singlePayment.MONTO > 0) {
          this.newPayment[singlePaymentIndex].CAMBIO =
            singlePayment.PAGO_PARC - singlePayment.MONTO;
        } else {
          this.newPayment[singlePaymentIndex].CAMBIO = 0;
        }

        // Check if theres is something missing to pay
        if (singlePaymentIndex > 0) {
          if (this.newPayment[singlePaymentIndex - 1].CAMBIO > 0) {
            this.newPayment[singlePaymentIndex].MONTO = 0;
          } else {
            this.newPayment[singlePaymentIndex].MONTO =
              this.newPayment[singlePaymentIndex - 1].MONTO -
              this.newPayment[singlePaymentIndex - 1].PAGO_PARC;
          }
        }
      }
    },
    /**
     *
     * @desc checks if the paymet method is credit and checl if client has enough credit
     */
    checkCredit(paymentMethod) {
      if (paymentMethod.FORMA_P === "CR") {
        this.sale.METODO_P = "D";
        this.sale.CONDICION_P = "R";
        if (
          paymentMethod.PAGO_PARC >
          this.clientInformation.LIMITE_CRED -
            this.clientInformation.BALANCE_CRED
        ) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `El cliente no cuenta con la linea de crédito suficiente`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          paymentMethod.PAGO_PARC =
            this.clientInformation.LIMITE_CRED -
            this.clientInformation.BALANCE_CRED;
          paymentMethod.CAMBIO = 0;
          this.sale.PREI_PAGO = this.clientInformation.D_CREDITO
            ? this.clientInformation.D_CREDITO
            : 0;
          this.calculateChange();
        }
      } else {
        this.sale.METODO_P = "P";
        this.sale.CONDICION_P = "C";
      }
    },
    /**
     *
     * @desc adds a new payment method to the payment
     */
    addPaymenMethod() {
      this.newPayment.push({
        FORMA_P: "",
        PAGO_PARC: 0,
        DEVULTO: 0,
        MONTO:
          this.newPayment[this.currentIndex].CAMBIO > 0
            ? 0
            : this.newPayment[this.currentIndex].MONTO -
              this.newPayment[this.currentIndex].PAGO_PARC,
        CAMBIO: 0,
        STATUS: "AC",
        FECHA_PAGO: new Date(),
        OBSERVACIONES: "",
        createMovement: false,
        affectBalance: false,
        requireCDFI: false,
        currentIndex: this.currentIndex + 1,
      });
      this.currentIndex += 1;
      this.openedDetails.push(this.currentIndex);
    },
    /**
     *
     * @desc validates that all payment methods are correct
     */
    validatesPayments() {
      let validPayments = [];
      let missingQuantity = this.saleInformation.total;

      for (const singlePayment of this.newPayment) {
        const singlePaymentWithProps = { ...singlePayment };
        if (singlePayment.FORMA_P && singlePayment.PAGO_PARC) {
          missingQuantity -= singlePayment.PAGO_PARC;
          singlePaymentWithProps.PAGO_PARC =
            singlePaymentWithProps.PAGO_PARC - singlePaymentWithProps.CAMBIO;
          validPayments.push(singlePaymentWithProps);
        }
      }

      return { validPayments, missingQuantity };
    },
    /**
     *
     * @desc validates the amount of change returned to the user
     */
    validChange() {
      for (const singlePayment of this.newPayment) {
        if (
          singlePayment.DEVUELTO > 0 &&
          singlePayment.CAMBIO + 1 < singlePayment.DEVUELTO
        ) {
          return false;
        }
      }

      return true;
    },
    /**
     *
     * @desc gets the IVA from an article
     */
    getIva(item) {
      if (item.article) {
        if (
          item.article.CANT_IVA !== undefined &&
          item.article.CANT_IVA !== null
        ) {
          return item.article.CANT_IVA;
        } else {
          return 16;
        }
      }
      return 0;
    },
    /**
     *
     * @desc gets the IPES from an article
     */
    getIeps(item) {
      if (item.article) {
        if (
          item.article.CANT_IEPS !== undefined &&
          item.article.CANT_IEPS !== null
        ) {
          return item.article.CANT_IEPS;
        } else {
          return 0;
        }
      }
      return 0;
    },
    transformSale(sale, validPayments) {
      let transformedSale = {};
      transformedSale.TIPO = sale.TIPO;
      transformedSale.OBSERVA = sale.OBSERVA;
      transformedSale.OBSERVA_IMP = sale.OBSERVA_IMP;
      transformedSale.FECHA = sale.FECHA;
      transformedSale.CLAVE_ALMACEN = sale.ALMACEN;
      transformedSale.CLAVE_CLIENTE = sale.CLIENTE;
      transformedSale.TOTAL = sale.total;
      transformedSale.SUBTOTAL = sale.subtotal;
      transformedSale.IVA = sale.iva;
      transformedSale.FLETE = sale.shippingQuantity;
      transformedSale.PORC_FLETE = sale.shippingPercentage;
      transformedSale.EXENTO = sale.EXENTO;
      transformedSale.IEPS = sale.ieps;
      transformedSale.STATUS = sale.STATUS;
      transformedSale.COSTOS = sale.COSTOS;
      transformedSale.DESCS = sale.DESCS;
      transformedSale.COST_GTO = sale.costs;
      transformedSale.DESC_GTO = sale.discounts;
      transformedSale.IMPRESO = sale.printTimes;
      transformedSale.STATUS = "AC";
      transformedSale.STATUS_EN = sale.sendMaterial ? "CO" : "PE";
      transformedSale.CONDICION_P = sale.CONDICION_P;
      transformedSale.METODO_P = sale.METODO_P;
      transformedSale.PERI_PAGO = sale.PERI_PAGO;
      transformedSale.POS = sale.POS;
      transformedSale.CLAVE_USO_SAT = sale.CLAVE_USO_SAT;
      transformedSale.CLAVE_REGIMEN = sale.CLAVE_REGIMEN;
      transformedSale.FORMA_P = sale.FORMA_P;
      transformedSale.NO_CUENTA = sale.NO_CUENTA;
      transformedSale.NO_CLABE = sale.NO_CLABE;
      transformedSale.NO_TARJETA = sale.NO_TARJETA;
      transformedSale.NO_CHEQUE = sale.NO_CHEQUE;
      transformedSale.requireInvoice = sale.requireInvoice;
      transformedSale.requireInvoiceAfterSend = sale.requireInvoiceAfterSend;
      transformedSale.requireParcialInvoiceAfterSend =
        sale.requireParcialInvoiceAfterSend;
      transformedSale.UUID_CFDI = sale.UUID_CFDI;
      transformedSale.TIPO_R = sale.TIPO_R;
      transformedSale.relateCFDI = sale.relateCFDI;
      transformedSale.divideCFDI = sale.divideCFDI;
      transformedSale.VENDEDOR = sale.VENDEDOR;
      transformedSale.NOMBRE_VENDEDOR = sale.NOMBRE_VENDEDOR;
      transformedSale.UTILIDAD = sale.UTILIDAD;
      transformedSale.sendMaterial = sale.sendMaterial;
      transformedSale.RESTA = sale.sendMaterial ? 0 : sale.total;
      transformedSale.useAdvancedPayment = sale.useAdvancedPayment;
      transformedSale.CLAVE_ANTICIPO = sale.CLAVE_ANTICIPO;
      transformedSale.CANTIDAD_ANTICIPO = sale.CANTIDAD_ANTICIPO;
      transformedSale.UTILIDAD = sale.UTILIDAD;
      transformedSale.FECHA_ENVIO = sale.FECHA_ENVIO;
      transformedSale.clientPickup = sale.clientPickup;
      transformedSale.OC_CLIENTE = sale.OC_CLIENTE;
      transformedSale.USUARIO_CREACION = sale.USUARIO_CREACION;
      transformedSale.USUARIO_CONFIRMACION = sale.USUARIO_CONFIRMACION;
      transformedSale.CLAVE_VEHICULO = sale.CLAVE_VEHICULO;
      transformedSale.CODIGO_ENVIO = sale.CODIGO_ENVIO;

      if (sale._id) transformedSale._id = sale._id;
      let items = [];
      for (let singleItem of sale.currentItems) {
        if (singleItem.article) {
          const transformedItem = {
            CLAVE_ART: singleItem.article._id,
            PRESENTACION: singleItem.presentation,
            CANTIDAD_PRESENTACION: singleItem.CANTIDAD_PRESENTACION,
            CONCEP_IE: singleItem.article.CLAVE_ART,
            DESCRIBEAR: singleItem.article.CLAVE_ART,
            LISTA_PRECIOS: singleItem.priceList,
            RENGLON: singleItem.RENGLON,
            CANTIDAD_PE: singleItem.quantity,
            CANTIDAD_EN: sale.sendMaterial ? singleItem.quantity : 0,
            CANTIDAD_FE: sale.sendMaterial ? 0 : singleItem.quantity,
            PORC_IVA: this.getIva(singleItem),
            PORC_IEPS: this.getIeps(singleItem),
            IVA_GTO: singleItem.IVA_GTO,
            IEPS_GTO: singleItem.IEPS_GTO,
            TOTAL: singleItem.finalPrice,
            COSTO_UNI: singleItem.price,
            DESC_GTO: singleItem.discountTotal,
            COST_GTO: singleItem.costTotal,
            DESCS: singleItem.discounts,
            COSTOS: singleItem.costs,
            EXENTO: singleItem.EXENTO,
            SUB_TOT: singleItem.subTotal,
            COSTO_MIN_VTA: singleItem.COSTO_MIN_VTA,
            UTILIDAD_UNI: singleItem.utility,
            UTILIDAD: singleItem.utilityTotal,
            RESTA: sale.sendMaterial ? 0 : singleItem.finalPrice,
          };
          if (singleItem._id) transformedItem._id = singleItem._id;
          items.push(transformedItem);
        }
      }
      transformedSale.items = items;

      // Set payment type to the one used to pay the most
      let posPaymenthMethods = "";
      let paymentMethodSelected = "";
      let mostPaidAmount = 0;
      for (const singlePayment of validPayments) {
        // Map payment methods and quantity
        for (const singlePaymentMethod of this.paymentTypes) {
          if (
            singlePayment.FORMA_P.toString() ===
            singlePaymentMethod._id.toString()
          ) {
            posPaymenthMethods += `${
              singlePaymentMethod.DESCRIBE_P
            }: $${toFixedNoRound(singlePayment.PAGO_PARC, 3)}\n`;
          }
        }

        if (singlePayment.PAGO_PARC > mostPaidAmount) {
          paymentMethodSelected = singlePayment.FORMA_P;
          mostPaidAmount = singlePayment.PAGO_PARC;
        }
      }

      transformedSale.FORMA_P = paymentMethodSelected;
      transformedSale.POS_METODOS_PAGO = posPaymenthMethods;

      // Set payment type of sale if the client uses credit
      for (const singlePayment of validPayments) {
        if (singlePayment.FORMA_P === "CR") {
          transformedSale.FORMA_P = this.porDefinirPaymentId;
        }
      }

      return transformedSale;
    },
    openChangeCalculator() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: CalculateChangeModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    updateQuantityPaid(paymentRow) {
      let finalQuantityPaid = 0;

      finalQuantityPaid += this.cambio.MIL * 1000;
      finalQuantityPaid += this.cambio.QUINIENTOS * 500;
      finalQuantityPaid += this.cambio.DOSCIENTOS * 200;
      finalQuantityPaid += this.cambio.CIEN * 100;
      finalQuantityPaid += this.cambio.CINCUENTA * 50;
      finalQuantityPaid += this.cambio.VEINTE * 20;
      finalQuantityPaid += this.cambio.DIEZ * 10;
      finalQuantityPaid += this.cambio.CINCO * 5;
      finalQuantityPaid += this.cambio.DOS * 2;
      finalQuantityPaid += this.cambio.UNO * 1;
      finalQuantityPaid += this.cambio.CINCUENTA_CEN * 0.5;

      paymentRow.PAGO_PARC = finalQuantityPaid;
      this.calculateChange();
    },
    updateChangeTotal(paymentRow) {
      let finalQuantityGiven = 0;

      finalQuantityGiven += this.devuelto.MIL * 1000;
      finalQuantityGiven += this.devuelto.QUINIENTOS * 500;
      finalQuantityGiven += this.devuelto.DOSCIENTOS * 200;
      finalQuantityGiven += this.devuelto.CIEN * 100;
      finalQuantityGiven += this.devuelto.CINCUENTA * 50;
      finalQuantityGiven += this.devuelto.VEINTE * 20;
      finalQuantityGiven += this.devuelto.DIEZ * 10;
      finalQuantityGiven += this.devuelto.CINCO * 5;
      finalQuantityGiven += this.devuelto.DOS * 2;
      finalQuantityGiven += this.devuelto.UNO * 1;
      finalQuantityGiven += this.devuelto.CINCUENTA_CEN * 0.5;

      paymentRow.DEVUELTO = finalQuantityGiven;
      this.calculateChange();
    },
    checkIfCanDivide() {
      for (const singlePayment of this.newPayment) {
        if (
          singlePayment.FORMA_P === this.efectivoPaymentId &&
          singlePayment.PAGO_PARC >= 2000
        ) {
          return true;
        }
      }
      return false;
    },
    checkCanDivideCashInvoice() {
      let canDivideInvoice = false;

      for (const singleItem of this.sale.currentItems) {
        if (singleItem.article && singleItem.quantity >= 1) {
          canDivideInvoice = true;
        }
      }

      for (const singleItem of this.sale.currentItems) {
        if (singleItem.price > 1999) {
          canDivideInvoice = false;
        }
      }

      if (this.sale.total > 100000) {
        this.sale.divideCFDI = false;
        return false;
      }

      return (
        canDivideInvoice &&
        this.checkIfCanDivide() &&
        this.checkIfCanInvoice(this.clientInformation._id) &&
        this.userPermissions &&
        this.userPermissions.accounting.saveInvoice === "S" &&
        (this.sale.requireInvoice || this.sale.requireInvoiceAfterSend) &&
        !this.sale.requireParcialInvoiceAfterSend
      );
    },
    handleRequireParcialInvoiceAfterSend() {
      this.sale.divideCFDI = false;
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        const singleWarehouseWithProps = { ...singleWarehouse };
        return singleWarehouseWithProps;
      }).filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        return singlePriceListWithProps;
      });
    },
    paymentTypes() {
      return this.$store.getters.PAYMENTTYPES.filter((siglePaymentType) => {
        return (
          siglePaymentType._id === this.efectivoPaymentId ||
          siglePaymentType._id === this.debitCardId ||
          siglePaymentType._id === this.creditCardId
        );
      });
    },
    users() {
      return this.$store.getters.USERS.map((singleUser) => {
        const userWithProps = { ...singleUser };
        userWithProps.permissionsName = userWithProps.permissions.name;
        if (!userWithProps.pointOfSale) {
          userWithProps.allowAddPos = true;
        } else {
          userWithProps.allowAddPos = false;
        }
        return userWithProps;
      });
    },
    userInformation() {
      return this.$store.getters.USER;
    },
    userPermissions() {
      return this.$store.getters.USER.permissions.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Global/style/PointOfSale.css";
</style>

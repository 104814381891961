<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Seleccionar vendedor</h1>

    <div class="table-header table-header-border">
      Cantidad de vendedores: <b>{{ sellers.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="sellers"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      ref="sellers"
      paginated
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      class="sellers-table"
      scrollable
      @click="(row) => selectSeller(row)"
    >
      <template #empty>
        <div class="has-text-centered">No hay vendedores</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          v-bind="column"
          :key="column.field"
          :visible="column.display"
          :label="column.label"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>

          <span> {{ props.row[column.field] }}</span>
        </b-table-column>
      </template>
    </b-table>

    <div class="add-global-controls">
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../event-bus";

// @ is an alias to /src
export default {
  name: "SelectSellerModal",
  props: [],
  components: {},
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      columns: [
        {
          field: "NOMBRE",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PATERNO",
          label: "Apellido paterno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "MATERNO",
          label: "Apellido materno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TEL_MOVIL",
          label: "Teléfono",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "EMAIL",
          label: "Correo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "RFC",
          label: "RFC",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CURP",
          label: "Curp",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PUESTO",
          label: "Puesto",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     *
     * @desc sends the seller information to the component that opened this component
     */
    selectSeller(seller) {
      EventBus.$emit("sellerSelectedSale", {
        seller: seller,
      });
      this.closeModal();
    },
  },
  computed: {
    sellers() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      }).filter((singleEmployee) => {
        return singleEmployee.TIPO === "VE" || singleEmployee.TIPO === "VC";
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>

<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Seleccionar cliente</h1>

    <div class="table-header table-header-border">
      Cantidad de clientes: <b>{{ clients.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="clients"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      ref="clients"
      paginated
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      class="clients-table"
      scrollable
      @click="(row) => selectClient(row)"
      default-sort="CLAVE_CLI"
    >
      <template #empty>
        <div class="has-text-centered">No hay clientes</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          v-bind="column"
          :key="column.field"
          :visible="column.display"
          :label="column.label"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>

          <span> {{ props.row[column.field] }}</span>
        </b-table-column>
      </template>
    </b-table>

    <div class="add-global-controls">
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../event-bus";

// @ is an alias to /src
export default {
  name: "SelectClientModal",
  props: [],
  components: {},
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      columns: [
        {
          field: "CLAVE_CLI",
          label: "Clave del cliente",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortyByKey,
        },
        {
          field: "NOMBRE_EMPRESA",
          label: "Razón social",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "RFC_CLI",
          label: "RFC",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_CLI",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "A_PATERNO",
          label: "Apellido paterno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "A_MATERNO",
          label: "Apellido materno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DIRECCION",
          label: "Direción",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     *
     * @desc sends the client information to the component that opened this component
     */
    selectClient(client) {
      EventBus.$emit("clientSelectedSale", {
        client: client,
      });
      this.closeModal();
    },
    sortyByKey(a, b, isAsc) {
      if (isAsc) {
        return a.CLAVE_CLI.localeCompare(b.CLAVE_CLI, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return b.CLAVE_CLI.localeCompare(a.CLAVE_CLI, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    },
  },
  computed: {
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };
        clientWithProps.remainingCredit =
          singleClient.LIMITE_CRED - singleClient.BALANCE_CRED;
        return clientWithProps;
      }).filter((singleClient) => {
        return singleClient.TIPO === "CL";
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>

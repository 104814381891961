<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Calculadora de cambio</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field label="Total (Requerido)" expanded label-position="inside">
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 16"
          :required="false"
          v-model="newChange.TOTAL"
          icon="cash"
          @input="calculateNewChange()"
        >
        </MoneyInput>
      </b-field>

      <b-field
        label="Cantidad recibida (Requerido)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 16"
          :required="false"
          v-model="newChange.RECIBIDO"
          icon="account-cash"
          @input="calculateNewChange()"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Cambio (Requerido)" expanded label-position="inside">
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 200"
          :required="false"
          v-model="newChange.CAMBIO"
          icon="cash"
          :disabled="true"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <h3 class="form-division">Cambio</h3>

    <div class="initial-dashboard-cards-container special-margin">
      <InformationCard
        name="Billetes de $1000.00"
        :value="changeNeeded[1000]"
        format="bold"
        width="four-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Billetes de $500.00"
        :value="changeNeeded[500]"
        format="bold"
        width="four-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Billetes de $200.00"
        :value="changeNeeded[200]"
        format="bold"
        width="four-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Billetes de $100.00"
        :value="changeNeeded[100]"
        format="bold"
        width="four-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Billetes de $50.00"
        :value="changeNeeded[50]"
        format="bold"
        width="four-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Billetes de $20.00"
        :value="changeNeeded[20]"
        format="bold"
        width="four-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Monedas de $10.00"
        :value="changeNeeded[10]"
        format="bold"
        width="four-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Monedas de $5.00"
        :value="changeNeeded[5]"
        format="bold"
        width="four-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Monedas de $2.00"
        :value="changeNeeded[2]"
        format="bold"
        width="four-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Monedas de $1.00"
        :value="changeNeeded[1]"
        format="bold"
        width="four-centerd"
        :moreDetails="false"
      />

      <InformationCard
        name="Monedas de $0.50"
        :value="changeNeeded[0.5]"
        format="bold"
        width="four-centerd"
        :moreDetails="false"
      />
    </div>

    <div class="add-global-controls">
      <b-button type="is-success" @click="calculateChange()"
        >Calcular cambio</b-button
      >
      <b-button type="is-danger" class="cancel-button" @click="closeModal()"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import InformationCard from "../../Global/components/InformationCard.vue";
// @ is an alias to /src
export default {
  name: "CalculateChangeModal",
  props: [],
  components: {
    InformationCard,
  },
  directives: { cleave },
  data() {
    return {
      newChange: {
        TOTAL: 0,
        RECIBIDO: 0,
        CAMBIO: 0,
      },
      changeNeeded: {
        1000: 0,
        500: 0,
        200: 0,
        100: 0,
        50: 0,
        20: 0,
        10: 0,
        5: 0,
        2: 0,
        1: 0,
        0.5: 0,
      },
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves or updates brand in the database depending on type
     */
    calculateChange() {
      let amount = this.newChange.CAMBIO;
      this.changeNeeded = {};
      [1000, 500, 200, 100, 50, 20, 10, 5, 2, 1, 0.5].map((coin) => {
        this.changeNeeded[coin] = Math.floor(amount / coin);
        amount = amount % coin;
      });
    },
    /**
     *
     * @desc calculates the change the the user needs to give the client
     */
    calculateNewChange() {
      this.newChange.CAMBIO = this.newChange.RECIBIDO - this.newChange.TOTAL;
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Global/style/InitialDashboard.css";
</style>

<template>
  <div class="container global-list-main-container">
    <Navbar :showSearch="false"></Navbar>
    <div class="absolut-width global-lists-second-section-container">
      <div class="global-list-main-container">
        <b-tabs type="is-boxed">
          <b-tab-item label="Punto de ventas" icon="point-of-sale">
            <PointOfSale />
          </b-tab-item>
          <b-tab-item label="Cotizaciones" icon="file-account-outline">
            <QuotesPosList />
          </b-tab-item>
          <b-tab-item label="Tickets pendientes de pago" icon="printer-pos">
            <MissingSalesListModal />
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import PointOfSale from "./PointOfSale";
import MissingSalesListModal from "../../Sales/components/MissingSalesListModal";
import QuotesPosList from "../../Sales/components/QuotesPosList";
import Navbar from "../components/Navbar";

export default {
  name: "PointOfSaleTabs",
  components: {
    PointOfSale,
    MissingSalesListModal,
    QuotesPosList,
    Navbar,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "pos");
  },
  async created() {
    let allPromises = [];
    if (this.$store.getters.PAYMENTTYPES.length === 0) {
      allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
    }
    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>

import { render, staticRenderFns } from "./QuotesPosList.vue?vue&type=template&id=696a8172&scoped=true&"
import script from "./QuotesPosList.vue?vue&type=script&lang=js&"
export * from "./QuotesPosList.vue?vue&type=script&lang=js&"
import style0 from "./QuotesPosList.vue?vue&type=style&index=0&id=696a8172&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "696a8172",
  null
  
)

export default component.exports